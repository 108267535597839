import { Form, Formik, FormikValues } from 'formik';
import Button from 'styleguide/components/Button/Button';
import * as React from 'react';
import { updateDefaultAddresses } from 'api/account/orders';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { number, object } from 'yup';
import { Status } from 'libs/utils/api/types';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';

const DefaultAddressesForm = () => {
  const { currentUser, updateAddresses } = React.useContext(UserContext);

  const addressOptions = currentUser.addresses.map(elem => ({
    key: elem.id,
    label: elem.description,
  }));

  const defaultAddressSchema = object().shape({
    shipAddressId: number().nullable(),
    billAddressId: number().nullable(),
  });
  const onSubmit = (values: FormikValues) => {
    updateDefaultAddresses(values).then(res => {
      if (res.status === Status.Ok) {
        updateAddresses(
          res.payload.addresses.filter(a => a.verified),
          res.payload.defaultShipAddress,
          res.payload.defaultBillAddress,
        );
      }
    });
  };

  return (
    <Formik
      initialValues={{
        shipAddressId: currentUser.defaultShipAddress ? currentUser.defaultShipAddress.id : null,
        billAddressId: currentUser.defaultBillAddress ? currentUser.defaultBillAddress.id : null,
      }}
      onSubmit={values => onSubmit(values)}
      validationSchema={defaultAddressSchema}
    >
      <Form>
        {addressOptions && (
          <div className="flex flex-col max-w-base mx-auto mt-6 px-4 -md:mb-6 md:flex-row md:gap-6">
            <div className="grow">
              <FormikFieldWrapper
                name="shipAddressId"
                options={addressOptions}
                componentType="combobox"
                labelComponent="Default Ship Address"
                data-cy="defaultShipAddressSelect"
                inPlaceError
              />
            </div>
            <div className="grow">
              <FormikFieldWrapper
                name="billAddressId"
                options={addressOptions}
                componentType="combobox"
                labelComponent="Default Bill Address"
                data-cy="defaultBillAddressSelect"
                inPlaceError
              />
            </div>
            <Button dataCy="updateDefaultAddressBtn" type="submit" color="blue" className="h-[46px] !py-0">
              Update Default Addresses
            </Button>
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default DefaultAddressesForm;
